import React from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './App.css';

import './scss/_breakpoints.scss';
import './scss/_colors.scss';
import './scss/_fonts.scss';
import './scss/_forms.scss';
import './scss/_front-lg.scss';
import './scss/_front-md.scss';
import './scss/_front-sm.scss';
import './scss/_front-xs.scss';
import './scss/_global.scss';
import './scss/_media.scss';
import './scss/_utilities.scss';
import './scss/front.scss';

import './scss/components/_button.scss';
import './scss/components/_details.scss';
import './scss/components/_footer.scss';
import './scss/components/_header.scss';
import './scss/components/_heading.scss';


import { Footer } from './components/Footer';
import { PageHome } from './pages/PageHome';
import { PageLegalNotices } from './pages/PageLegalNotices';
import { PagePersonalData } from './pages/PagePersonalData';
import { PageContacts } from './pages/PageContacts';
import { PagePlanSite } from './pages/PagePlanSite';
import { PageAccessibility } from './pages/PageAccessibility';

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <PageHome />
    },
    {
      path: '/mentions-legales',
      element: <PageLegalNotices />
    }, 
    {
      path: '/contact',
      element: <PageContacts />
    },
    {
      path: '/donnees-personelles',
      element: <PagePersonalData />
    },
    {
      path: '/plan',
      element: <PagePlanSite />
    }, 
    {
      path: '/déclaration-accessibilité',
      element: <PageAccessibility />
    }
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;
