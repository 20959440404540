import React, { useEffect } from 'react';

export const PagePersonalData = () => {
  useEffect(() => {
    document.title = 'HITinéraire - Données personnelles';
  }, []);
  return (
    <div id='page-contact' className='page-info'>
      <div className="layer-ring">
        <svg width="100%" viewBox="0 0 1119 1119" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="559.5" cy="559.5" r="494.5" stroke="#ED7F15" strokeWidth="90"/>
        </svg>
      </div>
      <div id='header-personal-data' className='header-info'>
        <h2>
          Données personnelles
        </h2>
        <p>
          Le contenu de ce site est créé, géré et actualisé par Lorient Agglomération
        </p>
        <p>
          Vous y accédez par l&apos;adresse suivante <a href="https://hitineraires.lorient-agglo.bzh/">https://hitineraires.lorient-agglo.bzh/</a>:
        </p>
      </div>
      <div id='contact-content' className='info-content'>
        <div id='right-info' className='info-container'>
          <p className='title-section'>
          Exercer vos droits
          </p>
          <br />
          <div className='info-section'>
            <p>
            Conformément à la Loi Informatique et Libertés et au RGPD, vous disposez des droits suivants :
            </p>
            <ul> 	
              <li>Droit d’accès (article 15 RGPD) et de rectification (article 16 RGPD)</li> 	
              <li>Droit à l’effacement de vos données à caractère personnel (article 17 RGPD)</li> 	
              <li>Droit de retirer à tout moment votre consentement (article 13-2c RGPD)</li> 
              <li>Droit à la limitation du traitement de vos données (article 18 RGPD)</li> 	
              <li>Droit d’opposition au traitement de vos données (article 21 RGPD)</li> 	
              <li>Droit d’introduire une réclamation auprès de la CNIL (article 77 RGPD)</li> 
            </ul>
            <p>
            Sauf si un motif d’intérêt légitime impérieux, ou relevant de l’autorité publique ou encore une mission d’intérêt public s’y oppose.
            </p>
            <p>
            Ces droits peuvent être exercés, sur simple demande par courrier électronique en contactant notre Délégué à la protection des données :
            </p>
            <ul> 	
              <li>à l’adresse <a href="mailto:dpo@agglo-lorient.fr">dpo@agglo-lorient.fr</a></li> 	
              <li>ou par courrier postal à Lorient Agglomération, Maison de l’Agglomération, CS 20 001, 56 314 Lorient Cedex en indiquant vos coordonnées (nom, prénom, adresse et d’une pièce d’identité en cours de validité (CNI, passeport)).</li> 
            </ul>
            <p>
            Pour mieux connaître vos droits, vous pouvez également consulter le site de la Commission Nationale de l’Informatique et des Libertés, accessible à l’adresse suivante : <a href="https://www.cnil.fr" target="_blank" rel="noreferrer">cnil.fr</a>
            </p>
          </div>
        </div>
        <div id='cookies-info' className='info-container'>
          <p className='title-section'>
          À propos des cookies
          </p>
          <br />
          <div className='info-section'>
            <p>
              <b>
                Qu&apos;est-ce qu&apos;un &quot;cookie&quot; ?
              </b>
            </p>
            <p>
              Un &quot;cookie&quot; est une suite d&apos;informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le renverra au serveur web chaque fois que vous vous y re-connecterez. Les cookies ont de multiples usages : ils peuvent servir à mémoriser votre identifiant client auprès d&apos;un site marchand, le contenu courant de votre panier d&apos;achat, un identifiant permettant de tracer votre navigation pour des finalités statistiques ou publicitaires, etc.
            </p>
            <a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur">En savoir plus sur les cookies et leur fonctionnement</a>
          </div>
        </div>
        <div id='types-cookies-info' className='info-container'>
          <p className='title-section'>
          Type de cookie déposé sur le site Hitinéraire - Lorient Agglomération :
          </p>
          <br />
          <div className='info-section'>
            <p>
              Nous n&apos;utilisons pas de cookies sur le site.
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};