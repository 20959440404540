import React, { useEffect } from 'react';

import mailIcon from '../images/raw/mail.svg';
import telIcon from '../images/raw/telephone.svg';
import houseIcon from '../images/raw/house.svg';

export const PageContacts = () => {

  useEffect(() => {
    document.title = 'HITinéraire - Nous contacter';
  }, []);

  return (
    <div id='page-contact' className='page-info'>
      <div id='header-contact' className='header-info'>
        <h2>
          Nous contacter
        </h2>
      </div>
      <div id='contact-site-header' className='info-content'>
        <div className='grid-content'>
          <div className='grid-info'>
            <div className='info-contact'>
              <img className='icon-info' src={mailIcon} width='50' />
              <p>
                Contact mail: slenoxaic@agglo-lorient.fr
              </p>
            </div>
          </div>
          <div className='grid-info'>
            <div className='info-contact'>
              <img className='icon-info' src={telIcon} width='50' />
              <p>
                Contact téléphone: 02 90 74 73 20
              </p>
            </div>
          </div>
          <div className='grid-info'>
            <div className='info-contact'>
              <img className='icon-info' src={houseIcon} width='50' />
              <p>
              MISSION SYSTÈME D’INFORMATION GÉOGRAPHIQUE TERRITORIAL
              </p>
              <p>
              BP 20001
              </p>
              <p>
              56314 LORIENT Cedex
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};