import React from 'react';

export const DiscoverPoints = ({label, text, children}) => {
  return (
    <li>
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        {children}
      </svg>
      <h3>
        {label}
      </h3>
      <p>
        {text}
      </p>
    </li>
  );
};