import React from 'react';

import logo from '../images/webp/logo.png';
import logoHit from '../images/webp/logo-hit.png';
import logoFr2023 from '../images/webp/logo-france-2030.png';
import arrowRight from '../images/webp/arrow-right.png';

export const Header = () => {
  return (
    <header className="site-header">
      <div className="flex f gap-4 align-center">
        <div className="flex gap-2 align-center">
          <img src={logoFr2023} alt="Financé en partie par France 2030" height="40" />
          <img src={logoHit} alt="Proposé par HITinéraire" height="40" />
        </div>
        <div>
          <img src={logo} alt="" height="60" />
        </div>
        <div className="flex gap-4 align-center xs:hidden">
          <a href="https://hitineraires.lorient-agglo.bzh/hitineraire" className="btn btn-secondary">
            <span>Découvrir l’app</span>
            <span>
              <img src={arrowRight} alt="" height="20" width="20" />
            </span>
          </a>
        </div>
      </div>
    </header>
  );
};