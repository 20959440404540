import React from 'react';

import arrowRight from '../images/webp/arrow-right.png';
import appSample from '../images/webp/app-sample.png';
import appStoreBadge from '../images/raw/app-store-badge.svg';
import playStoreBadge from '../images/webp/google-play-badge.png';
import passagePieton from '../images/webp/passage-pieton.png';
import banqueTerritoires from '../images/webp/BANQUEDESTERRITOIRES.png';
import lorient from '../images/webp/LORIENT.png';
import gouvernement from '../images/webp/GOUVERNEMENT.png';
import { DiscoverPoints } from '../components/DiscoverPoints';
import { FaqDetailsAndSummary } from '../components/FaqDetailsAndSummary';
import { Header } from '../components/Header';

export const PageHome = () => {

  React.useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash.length) {
      const urlIdSection = urlHash.substring(1);
      const element = document.getElementById(urlIdSection);
      if (element) {
        setTimeout(() => {
          window.scrollTo({
            top: element.offsetTop + 10,
            behavior:'smooth'
          }, );
        }, 100);
      }
    }
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="hero">
          <div className="container">
            <div className="grid grid-col-1 md:grid-col-12">
              <div className="md:col-start-2 md:col-end-11">
                <h1>
                    Des parcours <strong>adaptés</strong> à vos <strong>capacités</strong> dans votre <strong>quotidien</strong>
                </h1>
              </div>
            </div>
          </div>

          <div className="layer-ring">
            <svg width="100%" viewBox="0 0 1119 1119" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="559.5" cy="559.5" r="494.5" stroke="#ED7F15" strokeWidth="130"/>
            </svg>
          </div>
        </section>

        <section id="decouvrir">
          <header>
            <div className="container">
              <div className="grid grid-col-1 md:grid-col-2 align-center md:gap-4">
                <div className="excerpt">
                  <h2>Une application pour la mobilité de tous qui s’adapte aux capacités</h2>
                  <p>Identifiez les lieux accessibles en fonction de vos besoins et de leur niveau d’accessibilité et recherchez les meilleurs itinéraires adaptés à vos capacités (en transports collectifs, à pied ou en fauteuil roulant).</p>
                </div>
                <figure>
                  <img src={appSample} alt="Aperçu de l'application HITinéraire pour la ville de Lorient. L'aperçu est visible sur tablette et sur mobile et montre la carte de la ville, avec les rues accessibles mises en évidence." />
                </figure>
              </div>
            </div>
          </header>

          <div className="container">
            <ul className="no-style grid grid-col-1 md:grid-col-3 gap-4">
              <DiscoverPoints 
                label='Parcours' 
                text='Obtenez l’itinéraire le plus adapté vers votre destination'
              >
                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_29_578)">
                    <path d="M0 22.5C0 34.9264 10.0736 45 22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0" stroke="#ED7F15" strokeWidth="5" />
                    <path d="M14.4999 17.6666H17.6857C17.8345 17.6666 17.9806 17.7063 18.1088 17.7818C18.237 17.8573 18.3427 17.9657 18.4148 18.0958L20.2498 21.4041C20.301 21.5032 20.3715 21.591 20.4573 21.6623C20.543 21.7337 20.6422 21.7871 20.7489 21.8194C20.8557 21.8517 20.9678 21.8622 21.0788 21.8504C21.1897 21.8385 21.2971 21.8045 21.3946 21.7504C21.4921 21.6963 21.5778 21.6231 21.6465 21.5353C21.7152 21.4475 21.7657 21.3467 21.7947 21.239C21.8238 21.1314 21.831 21.0189 21.8158 20.9084C21.8007 20.7979 21.7635 20.6916 21.7064 20.5957L19.8731 17.2858C19.6564 16.8958 19.3393 16.5709 18.9547 16.3448C18.57 16.1187 18.1319 15.9996 17.6857 16H14.4999C14.2789 16 14.0669 16.0878 13.9107 16.2441C13.7544 16.4004 13.6666 16.6123 13.6666 16.8333C13.6666 17.0543 13.7544 17.2663 13.9107 17.4226C14.0669 17.5788 14.2789 17.6666 14.4999 17.6666Z" fill="#1D1D1B" />
                    <path d="M31.6871 23.6823L27.5205 20.3491C27.398 20.2509 27.2501 20.1894 27.0941 20.1716C26.9381 20.1538 26.7803 20.1805 26.6387 20.2485C26.4972 20.3165 26.3778 20.4231 26.2942 20.5561C26.2106 20.689 26.1663 20.8429 26.1664 20.9999V23.4998H12.8333C12.6123 23.4998 12.4004 23.5876 12.2441 23.7439C12.0878 23.9002 12 24.1121 12 24.3332C12 24.5542 12.0878 24.7661 12.2441 24.9224C12.4004 25.0787 12.6123 25.1665 12.8333 25.1665H26.1664V27.6664C26.1663 27.8234 26.2106 27.9773 26.2942 28.1102C26.3778 28.2432 26.4972 28.3498 26.6387 28.4178C26.7803 28.4858 26.9381 28.5125 27.0941 28.4947C27.2501 28.4769 27.398 28.4154 27.5205 28.3172L31.6871 24.984C31.7847 24.9059 31.8636 24.8069 31.9177 24.6942C31.9719 24.5816 32 24.4582 32 24.3332C32 24.2082 31.9719 24.0848 31.9177 23.9721C31.8636 23.8594 31.7847 23.7604 31.6871 23.6823Z" fill="#1D1D1B" />
                  </g>
                  <defs>
                    <clipPath id="clip0_29_578">
                      <rect width="45" height="45" rx="22.5" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </DiscoverPoints>
              <DiscoverPoints
                label='Obstacles'
                text='Identifiez et signalez les obstacles sur votre trajet'
              >
                <g clipPath="url(#clip0_77_148)">
                  <path d="M0 22.5C0 34.9264 10.0736 45 22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0" stroke="#ED7F15" strokeWidth="5" />
                  <g clipPath="url(#clip1_77_148)">
                    <path d="M32.1667 15.5H13.8333C13.6123 15.5 13.4004 15.5878 13.2441 15.7441C13.0878 15.9004 13 16.1123 13 16.3333V23.8333C13 24.0543 13.0878 24.2663 13.2441 24.4226C13.4004 24.5789 13.6123 24.6667 13.8333 24.6667H32.1667C32.3877 24.6667 32.5996 24.5789 32.7559 24.4226C32.9122 24.2663 33 24.0543 33 23.8333V16.3333C33 16.1123 32.9122 15.9004 32.7559 15.7441C32.5996 15.5878 32.3877 15.5 32.1667 15.5ZM31.3333 21.3333L27.1667 17.1667H31.3333V21.3333ZM28.8333 23H23L17.1667 17.1667H23L28.8333 23ZM14.6667 18.8333L18.8333 23H14.6667V18.8333Z" fill="#202631" />
                    <path d="M29.6667 13.8334H28V15.5H29.6667V13.8334Z" fill="#202631" />
                    <path d="M18 13.8334H16.3333V15.5H18V13.8334Z" fill="#202631" />
                    <path d="M18 25.5H16.3333V30.515H13.8333V32.1667H20.5V30.515H18V25.5Z" fill="#202631" />
                    <path d="M29.6667 30.515V25.5H28V30.515H25.5V32.1667H32.1667V30.515H29.6667Z" fill="#202631" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_77_148">
                    <rect width="45" height="45" rx="22.5" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_77_148">
                    <rect width="20" height="20" fill="white" transform="translate(13 13)" />
                  </clipPath>
                </defs>
              </DiscoverPoints>
              <DiscoverPoints 
                label='Alertes' 
                text='Obtenez des informations en temps réel sur votre parcours'
              >
                <g clipPath="url(#clip0_77_137)">
                  <path d="M0 22.5C0 34.9264 10.0736 45 22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0" stroke="#ED7F15" strokeWidth="5" />
                  <g clipPath="url(#clip1_77_137)">
                    <path d="M32.7013 28.404L25.2772 14.369C24.6097 13.1115 23.0497 12.6332 21.7922 13.3007C21.3372 13.5423 20.9655 13.914 20.7238 14.369L13.2997 28.404C12.6388 29.6573 13.1188 31.2098 14.373 31.8707C14.7438 32.0665 15.158 32.1682 15.5772 32.1665H30.4238C31.8405 32.1707 32.993 31.0257 32.9972 29.6082C32.9988 29.1882 32.8972 28.7748 32.7013 28.404ZM23.0005 29.6665C22.3105 29.6665 21.7505 29.1065 21.7505 28.4165C21.7505 27.7265 22.3105 27.1665 23.0005 27.1665C23.6905 27.1665 24.2505 27.7265 24.2505 28.4165C24.2505 29.1065 23.6905 29.6665 23.0005 29.6665ZM23.4422 25.4998H22.558C22.338 25.4998 22.1555 25.329 22.1422 25.109L21.7772 19.2757C21.7622 19.0357 21.953 18.8332 22.193 18.8332H23.8063C24.0463 18.8332 24.2372 19.0357 24.2222 19.2757L23.8572 25.109C23.8438 25.329 23.6622 25.4998 23.4422 25.4998Z" fill="#202631" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_77_137">
                    <rect width="45" height="45" rx="22.5" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_77_137">
                    <rect width="20" height="20" fill="white" transform="translate(13 13)" />
                  </clipPath>
                </defs>
              </DiscoverPoints>
              <DiscoverPoints 
                label='Temps réel'
                text='Planifiez votre trajet en fonction des travaux, des obstacles mobiles et des évènements'
              >
                <g clipPath="url(#clip0_77_163)">
                  <path d="M0 22.5C0 34.9264 10.0736 45 22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0" stroke="#ED7F15" strokeWidth="5" />
                  <g clipPath="url(#clip1_77_163)">
                    <path d="M22 13C16.4775 13 12 17.4775 12 23C12 28.5225 16.4775 33 22 33H22.8333V31.3333H22C17.3975 31.3333 13.6667 27.6025 13.6667 23C13.6667 18.3975 17.3975 14.6667 22 14.6667C26.6025 14.6667 30.3333 18.3975 30.3333 23V23.8333H32V23C32 17.4775 27.5225 13 22 13Z" fill="#202631" />
                    <path d="M31.1667 29.6666H25.3333C24.8733 29.6666 24.5 30.04 24.5 30.5C24.5 30.96 24.8733 31.3333 25.3333 31.3333H31.1667C31.6267 31.3333 32 30.96 32 30.5C32 30.04 31.6267 29.6666 31.1667 29.6666Z" fill="#202631" />
                    <path d="M31.1667 26.3334H25.3333C24.8733 26.3334 24.5 26.7067 24.5 27.1667C24.5 27.6267 24.8733 28 25.3333 28H31.1667C31.6267 28 32 27.6267 32 27.1667C32 26.7067 31.6267 26.3334 31.1667 26.3334Z" fill="#202631" />
                    <path d="M26.1667 23C26.1667 22.7009 26.0067 22.425 25.7467 22.2767L19.9133 18.9434C19.655 18.7959 19.3383 18.7967 19.0817 18.9459C18.8242 19.095 18.6667 19.3692 18.6667 19.6667V26.3334C18.6667 26.63 18.8242 26.905 19.0817 27.0542C19.2108 27.1292 19.355 27.1667 19.5 27.1667C19.6425 27.1667 19.7858 27.13 19.9133 27.0567L25.7467 23.7234C26.0067 23.575 26.1667 23.2992 26.1667 23Z" fill="#202631" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_77_163">
                    <rect width="45" height="45" rx="22.5" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_77_163">
                    <rect width="20" height="20" fill="white" transform="translate(12 13)" />
                  </clipPath>
                </defs>
              </DiscoverPoints>
              <DiscoverPoints 
                label='Point d’intérêt'
                text='Découvrez les lieux d’intérêts à proximité et leur accessibilité'
              >
                <g clipPath="url(#clip0_77_190)">
                  <path d="M0 22.5C0 34.9264 10.0736 45 22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0" stroke="#ED7F15" strokeWidth="5" />
                  <g clipPath="url(#clip1_77_190)">
                    <path d="M32.2867 20.1175L26.3858 19.26L23.75 13.9167C23.6734 13.7865 23.5641 13.6787 23.433 13.6037C23.3019 13.5288 23.1535 13.4894 23.0025 13.4894C22.8515 13.4894 22.7031 13.5288 22.572 13.6037C22.4409 13.6787 22.3316 13.7865 22.255 13.9167L19.6142 19.26L13.7133 20.1175C13.5595 20.1397 13.4149 20.2045 13.2959 20.3046C13.1769 20.4047 13.0883 20.5361 13.0401 20.6839C12.9919 20.8317 12.986 20.9901 13.0231 21.1411C13.0602 21.2921 13.1388 21.4297 13.25 21.5383L17.5208 25.7L16.5133 31.5775C16.4871 31.7307 16.5042 31.8883 16.5628 32.0323C16.6214 32.1763 16.7191 32.3011 16.8449 32.3924C16.9707 32.4838 17.1196 32.5382 17.2747 32.5494C17.4298 32.5606 17.5849 32.5282 17.7225 32.4558L23 29.6817L28.2775 32.4558C28.4151 32.5282 28.5702 32.5606 28.7253 32.5494C28.8804 32.5382 29.0293 32.4838 29.1551 32.3924C29.2809 32.3011 29.3786 32.1763 29.4372 32.0323C29.4958 31.8883 29.5129 31.7307 29.4867 31.5775L28.4792 25.7L32.75 21.5383C32.8612 21.4298 32.9398 21.2923 32.977 21.1414C33.0141 20.9906 33.0084 20.8323 32.9604 20.6845C32.9124 20.5368 32.824 20.4054 32.7052 20.3052C32.5865 20.205 32.4421 20.14 32.2883 20.1175H32.2867Z" fill="#202631" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_77_190">
                    <rect width="45" height="45" rx="22.5" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_77_190">
                    <rect width="20" height="20" fill="white" transform="translate(13 13)" />
                  </clipPath>
                </defs>
              </DiscoverPoints>
              <DiscoverPoints 
                label='Multi-modals'
                text='Une continuité des parcours fiable faisant le lien entre différentes solutions de déplacements'
              >
                <g clipPath="url(#clip0_77_177)">
                  <path d="M0 22.5C0 34.9264 10.0736 45 22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0" stroke="#ED7F15" strokeWidth="5" />
                  <g clipPath="url(#clip1_77_177)">
                    <path d="M25.5 19.6666H29.6667C29.8877 19.6666 30.0996 19.5788 30.2558 19.4225L31.9225 17.7558C32.0787 17.5995 32.1665 17.3876 32.1665 17.1666C32.1665 16.9457 32.0787 16.7337 31.9225 16.5775L30.2558 14.9108C30.0996 14.7545 29.8877 14.6667 29.6667 14.6666H25.5V19.6666Z" fill="#202631" />
                    <path d="M25.5 31.3333H23.8333V13.8333C23.8333 13.6123 23.7455 13.4004 23.5892 13.2441C23.433 13.0878 23.221 13 23 13C22.779 13 22.567 13.0878 22.4107 13.2441C22.2544 13.4004 22.1667 13.6123 22.1667 13.8333V18H16.3333C16.1123 18 15.9004 18.0879 15.7442 18.2442L14.0775 19.9108C13.9213 20.0671 13.8335 20.279 13.8335 20.5C13.8335 20.721 13.9213 20.9329 14.0775 21.0892L15.7442 22.7558C15.9004 22.9121 16.1123 23 16.3333 23H22.1667V31.3333H20.5C20.279 31.3333 20.067 31.4211 19.9107 31.5774C19.7544 31.7337 19.6667 31.9457 19.6667 32.1667C19.6667 32.3877 19.7544 32.5996 19.9107 32.7559C20.067 32.9122 20.279 33 20.5 33H25.5C25.721 33 25.933 32.9122 26.0892 32.7559C26.2455 32.5996 26.3333 32.3877 26.3333 32.1667C26.3333 31.9457 26.2455 31.7337 26.0892 31.5774C25.933 31.4211 25.721 31.3333 25.5 31.3333Z" fill="#202631" />
                    <path d="M30.2558 21.5775C30.0996 21.4212 29.8877 21.3334 29.6667 21.3334H25.5V26.3334H29.6667C29.8877 26.3333 30.0996 26.2455 30.2558 26.0892L31.9225 24.4225C32.0787 24.2663 32.1665 24.0543 32.1665 23.8334C32.1665 23.6124 32.0787 23.4005 31.9225 23.2442L30.2558 21.5775Z" fill="#202631" />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_77_177">
                    <rect width="45" height="45" rx="22.5" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_77_177">
                    <rect width="20" height="20" fill="white" transform="translate(13 13)" />
                  </clipPath>
                </defs>
              </DiscoverPoints>
            </ul>
          </div>
          <footer className="grid align-center justify-center">
            <div className="txt-center">
              <a id='access_app' href="https://hitineraires.lorient-agglo.bzh/hitineraire" className="btn btn-primary">
                Découvrir l’application
              </a>
              <p className="xs:hidden">
                <small>Disponible également sur <a href="https://play.google.com/store/apps/details?id=com.handimaplorientmobile">Android</a> et <a href="https://apps.apple.com/fr/app/hitin%C3%A9raire/id6446932849">iOS</a></small>
              </p>
              <div className="md:hidden">
                <p className="flex align-center justify-center gap-2">
                  <a href="#" target="_blank">
                    <img src={appStoreBadge} alt="Télécharger dans l'App store" title="Télécharger dans l'App store" width="150" />
                  </a>

                  <a href="#" target="_blank">
                    <img src={playStoreBadge} alt="Disponible sur Google Play" title="Disponible sur Google Play" width="150" />
                  </a>
                </p>
              </div>
            </div>
          </footer>
        </section>

        <section id="faq">
          <div className="container">
            <div className="grid grid-col-1 md:grid-col-2 gap-4 align-start">
              <header>
                <h2>
                    Retrouvez toutes les réponses à vos questions pour planifier votre parcours
                </h2>
              </header>
              <div className="grid grid-col-1 gap-2">
                <FaqDetailsAndSummary 
                  summary='Comment déterminer un itinéraire ?' 
                  details='Rendez-vous sur l’application HITinéraire à l’aide du bouton "DÉCOUVRIR L’APP".
                  Ensuite remplissez les champs de Départ/Arrivée, ainsi que votre profil de mobilité, pour calculer un nouvel itinéraire.'
                />
                {/* <FaqDetailsAndSummary 
                  summary='Comment améliorer mon expérience utilisateur ?' 
                  details='Ac elit rhoncus nisi egestas et a curabitur. Sagittis ut quam lorem in tempor in blandit.'
                /> */}
                <FaqDetailsAndSummary 
                  summary='Les horaires des bus sont-ils en temps réel ?' 
                  details='Non, ce sont les tables d’horaires théoriques fournies par Izilo qui sont utilisées'
                />
                <FaqDetailsAndSummary 
                  summary='La feuille de route me sert à quoi ?' 
                  details='Elle vous décrit les différentes étapes à suivre pour emprunter l’itinéraire calculé.'
                />
                <FaqDetailsAndSummary 
                  summary='Comment partager un itinéraire ?' 
                  details='Il est possible de partager un itinéraire calculé à l’aide du bouton "Partager mon itinéraire".
                  Un lien prêt à envoyer est alors copié dans le presse-papiers. Il vous suffit de le coller dans un email ou un message.'
                />
                {/* <FaqDetailsAndSummary 
                  summary='Comment gérer les alertes sur mon parcours ?' 
                  details='Ac elit rhoncus nisi egestas et a curabitur. Sagittis ut quam lorem in tempor in blandit.'
                /> */}
                <FaqDetailsAndSummary 
                  summary='Comment signaler un obstacle ?' 
                  details='Cette fonctionnalité n’est pas encore disponible, mais elle le sera bientôt !'
                />
                <FaqDetailsAndSummary 
                  summary='Comment ajouter ou modifier un lieu sur la carte ?' 
                  details='Pour ça, rendez-vous sur la page de contact.'
                />
                <FaqDetailsAndSummary 
                  summary='Puis-je utiliser mon parcours dans un autre outil ?' 
                  details='Il est possible de télécharger l’itinéraire calculé dans un fichier (format GPX), et d’importer ce même fichier dans un GPS qui l’autorise.'
                />
                <FaqDetailsAndSummary 
                  summary='Je ne veux pas prendre les transports collectifs, je fais comment ?' 
                  details='L’option "En transport" peut être désactivée.'
                />
                <FaqDetailsAndSummary 
                  summary='Je veux donner un avis sur l’outil' 
                  details='Pour ça, rendez-vous sur la page de contact.'
                />
                
              </div>
            </div>
          </div>

          <div className="layer-ring">
            <svg width="100%" viewBox="0 0 893 893" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="446.5" cy="446.5" r="401.5" stroke="white" strokeOpacity="0.1" strokeWidth="90"/>
            </svg>
          </div>
        </section>

        <section id="a-propos">
          <div className="container">
            <div className="grid grid-col-1 md:grid-col-2 align-center gap-4">
              <figure>
                <img src={passagePieton} alt="Une personne marchant à l'aide d'une canne traverse une rue sur un passage piéton. La canne touche des repères spéciaux aidant au repérage." loading="lazy" className="block" />
              </figure>

              <article>
                <h2>
                    À propos
                </h2>

                <p>
                Précurseur en matière de collecte et de valorisation des données géographiques, Lorient Agglomération a lancé en 2013 un calculateur d’itinéraires qui s’adapte aux capacités des personnes.                </p>

                <p>
                En 2023, avec l’impulsion du projet France 2030 « Handicap Innovation Territoire », l’outil numérique renommé « HITinéraire » évolue pour s’adapter aux nouveaux usages et besoins de la population.                </p>

                <p>
                    Depuis 2012, Lorient Agglomération, ses communes membres et la Commission Intercommunale pour l’Accessibilité sont engagés dans une démarche novatrice pour améliorer l’accessibilité et les déplacements dans l’espace public.
                </p>

                <p>
                    Les itinéraires sont classés selon leur difficulté maximale (indices d’accessibilité) et évoluent en fonction de votre profil.
                </p>

                <a href="#">
                  <span>En savoir +</span>
                  <span>
                    <img src={arrowRight} alt="" height="20" width="20" />
                  </span>
                </a>
              </article>
            </div>
          </div>
        </section>

        <section id="temoignages">
          <div className="container">
            <div className="grid grid-col-1 md:grid-col-12">
              <div className="md:col-start-3 md:col-end-10">
                <figure className="txt-center">
                  <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M51.8787 33.7264L51.6206 34.9352L52.8567 34.9352C52.9214 34.9352 52.9765 34.9304 53.016 34.9259C53.0426 34.9228 53.0678 34.9192 53.0839 34.9168C56.4833 34.928 59.7411 36.2832 62.1457 38.6877C64.5602 41.1023 65.9167 44.3771 65.9167 47.7917C65.9167 51.2064 64.5602 54.4812 62.1457 56.8957C59.7313 59.3101 56.4567 60.6666 53.0422 60.6667C49.6286 60.6628 46.356 59.305 43.9422 56.8912C41.5283 54.4774 40.1705 51.2046 40.1667 47.7909C40.1667 47.6848 40.1784 47.5688 40.1998 47.409C40.2035 47.3812 40.2078 47.3507 40.2123 47.3181C40.231 47.1834 40.2548 47.0123 40.2683 46.8451L40.2833 46.6582L40.2296 46.4785C40.2012 46.3837 40.1802 46.287 40.1667 46.189C40.183 36.6955 43.99 28.0463 47.9287 21.6437C51.8702 15.2365 55.8926 11.1618 56.1939 10.8606L56.194 10.8605C56.5534 10.501 57.0318 10.2853 57.5393 10.254C58.0467 10.2228 58.548 10.3782 58.9488 10.6909C59.3496 11.0036 59.6223 11.4521 59.7154 11.9518C59.8085 12.4516 59.7157 12.9682 59.4544 13.4043L59.4448 13.4202L59.4359 13.4365C55.94 19.7947 53.394 26.6305 51.8787 33.7264Z" stroke="#ED7F15" strokeWidth="2"/>
                    <path d="M15.7954 33.7264L15.5372 34.9352L16.7733 34.9352C16.8381 34.9352 16.8932 34.9304 16.9327 34.9259C16.9593 34.9228 16.9845 34.9192 17.0006 34.9168C19.5323 34.9251 22.0057 35.6797 24.1113 37.0866C26.2286 38.5013 27.8788 40.5121 28.8533 42.8647C29.8278 45.2173 30.0827 47.806 29.5859 50.3035C29.0892 52.801 27.8629 55.0951 26.0623 56.8957C24.2617 58.6963 21.9676 59.9226 19.4701 60.4194C16.9726 60.9161 14.3839 60.6612 12.0313 59.6867C9.67869 58.7122 7.66788 57.062 6.25316 54.9447C4.83844 52.8274 4.08333 50.3382 4.08333 47.7917C4.08333 47.6854 4.09498 47.5692 4.11647 47.409C4.1202 47.3812 4.12444 47.3507 4.12898 47.3181C4.14771 47.1834 4.17152 47.0123 4.18495 46.8451L4.19998 46.6582L4.14625 46.4785C4.11791 46.3837 4.0969 46.287 4.08338 46.189C4.09965 36.6955 7.90666 28.0463 11.8454 21.6437C15.7869 15.2365 19.8093 11.1619 20.1105 10.8606L20.1106 10.8605C20.47 10.501 20.9485 10.2853 21.4559 10.254L21.3945 9.25593L21.4559 10.254C21.9633 10.2228 22.4647 10.3782 22.8655 10.6909C23.2663 11.0036 23.5389 11.4521 23.6321 11.9518C23.7252 12.4516 23.6323 12.9682 23.371 13.4043L23.3615 13.4202L23.3526 13.4365C19.8567 19.7947 17.3107 26.6305 15.7954 33.7264Z" stroke="#ED7F15" strokeWidth="2"/>
                  </svg>

                  <blockquote>
                  C’est un exemple inspirant pour de nombreuses collectivités cherchant à améliorer l’accessibilité pour tous…</blockquote>
                  <figcaption>
                    <small>
                        souligne Fabrice Loher, président de Lorient Agglomération, maire de Lorient.
                    </small>
                  </figcaption>

                  <div className="layer-ring">
                    <svg width="100%" viewBox="0 0 1119 1119" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="559.5" cy="559.5" r="494.5" stroke="#ED7F15" strokeWidth="130"/>
                    </svg>
                  </div>
                </figure>

                <ul id="partenaires" className="no-style grid grid-col-1 md:grid-col-2 gap-2 align-center justify-center">
                  <li className="md:col-2">
                    <img src={lorient} alt="Lorient Agglomération" loading="lazy" className="block" />
                  </li>
                  <li>
                    <img src={banqueTerritoires} alt="Banque des territoires, groupe caisse des dépôts" loading="lazy" className="block" />
                  </li>
                  <li>
                    <img src={gouvernement} alt="Gouvernement Français" loading="lazy" className="block" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};