import React, { useEffect } from 'react';

export const PageLegalNotices = () => {

  useEffect(() => {
    document.title = 'HITinéraire - Mentions légales';
  }, []);

  return (
    <div id='page-legal' className='page-info'>
      <div className="layer-ring">
        <svg width="100%" viewBox="0 0 1119 1119" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="559.5" cy="559.5" r="494.5" stroke="#ED7F15" strokeWidth="90"/>
        </svg>
      </div>
      <div id='header-legal' className='header-info'>
        <h2>
            Mentions légales
        </h2>
        <p>
            Le contenu de ce site est créé, géré et actualisé par Lorient Agglomération.
        </p>
        <p>
            Vous y accédez par l&apos;adresse suivante : <a href="https://hitineraires.lorient-agglo.bzh/">https://hitineraires.lorient-agglo.bzh/</a>
        </p>
      </div>
      <div id='legal-content' className='info-content'>
        <div id='editor-info' className='info-container'>
          <p className='title-section'>
            Éditeur du site
          </p>
          <br />
          <div id='info-section'>
            <p>
            Lorient Agglomération
            </p>
            <br />
            <p>
            Maison de l’Agglomération
            CS 20 001
            56 314 Lorient Cedex
            </p>
            <br />
            <p>
            Tél : 02 90 74 73 20
            </p>
            <br />
            <p>
            e-mail : contact@agglo-lorient.fr
            </p>
            <br />
            <p>
            représenté par Sylvaine LE NOXAÏC Responsable Mission SIG Territorial
            </p>
          </div>
        </div>
        <div id='location-info' className='info-container'>
          <p className='title-section'>
            Hébergement
          </p>
          <br />
          <div className='info-section'>
            <p>
            Ikoula
            </p>
            <br />   
            <p>
            2 Cité Paradis
            </p>
            <br/>
            <p>
            75010 PARIS
            </p>
            <br/>
            <br />
            <p>
            Mail de contact RGPD: <a href="mail:dpo@ikoula.com">dpo@ikoula.com</a>
            </p>
            <br />
            <a href="https://www.ikoula.com/">www.ikoula.com</a>
          </div>
        </div>
        <div id='dev-info' className='info-container'>
          <p className='title-section'>
            Développement
          </p>
          <br />
          <div id='info-section'>
            <p>
              Someware
            </p>
            <br />
            <p>
              11 Rue Julien Gracq
            </p>
            <br />
            <p>
             35690 ACIGNE
            </p>
            <br />
            <p>
              Mail de contact: <a href="mail:contact@someware.fr">contact@someware.fr</a>
            </p>
            <br/>
            <a href="https://www.someware.fr/">www.someware.fr</a>
          </div>
        </div>
        <div id='copyright-info-1' className='info-container'>
          <p className='title-section'>
            1. COPYRIGHT DES DROITS D’AUTEURS
          </p>
          <br />
          <div className='info-section'>
            <p>L&apos;ensemble des contenus, pages, scripts, icônes ou sons de ce site sont la propriété exclusive de Lorient Agglomération ou de ses partenaires. Toute production, reproduction ou représentation de ce site, en tout ou partie (textes, sons ou images), sur quelque support que ce soit est interdite. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur.</p>
            <br />
            <p>
              Lorient Agglomération n&apos;est pas responsable du contenu de tout autre site auquel vous pourriez avoir accès via le site <a href="https://hitineraires.lorient-agglo.bzh/">https://hitineraires.lorient-agglo.bzh</a>
            </p>
          </div>
        </div>
        <div id='copyright-info-2' className='info-container'>
          <p className='title-section'>
          2. CONTENUS ET INFORMATIONS EDITORIALES DE MISE À JOUR
          </p>
          <br />
          <div className='info-section'>
            <p>Le contenu du site est donné à titre informatif. Ces informations ne sont pas à caractère contractuel et ne sont pas exhaustives. Lorient Agglomération ne peut être tenu pour responsable de l’inexactitude, erreurs, omissions des données diffusées sur un site. Toutefois l’équipe éditoriale porte beaucoup d’attention au caractère d’exactitude, n’hésitez pas si vous trouviez une information incorrecte dans nos pages à nous le faire savoir.</p>
          </div>
        </div>
        <div id='copyright-info-3' className='info-container'>
          <p className='title-section'>
            3. OFFRES COMMERCIALES – CONDITIONS DE VENTE
          </p>
          <br />
          <div className='info-section'>
            <p>
              Offres commerciales proposées sur le site et liens vers des professionnels :
            </p>
            <br />
            <p>
              Lorient Agglomération a pris toutes les mesures à sa disposition pour vérifier l&apos;exactitude des informations commerciales présentes sur son site web et/ou présentées via un lien vers un tiers depuis son site web. Ces informations peuvent être d&apos;ordre tarifaire, descriptive, photographiques et/ou illustratives (vidéo, son, etc...). Certaines informations peuvent être erronées et/ou incomplètes. Lorient Agglomération ne peut être tenu responsable de changements ne lui ayant pas été communiqués ou de renseignements erronés. Nous vous conseillons de vérifier auprès des prestataires.
            </p>
            <br />
            <p>
              Responsabilité de Lorient Agglomération. Lorient Agglomération a apporté le plus grand soin à la création et à la réalisation du Site. Néanmoins, elle ne saurait vous garantir le caractère exact et exhaustif des informations fournies sur le Site
            </p>
            <br />
            <p>
            Malgré tous ses efforts, elle ne peut garantir que le Site soit sans virus et ne pourra en aucun cas être tenue pour responsable des dommages en résultant.
            </p>
          </div>
        </div>
        <div id='copyright-info-4' className='info-container'>
          <p className='title-section'>
            4. MODIFICATION ET MISE A JOUR DU SITE
          </p>
          <br />
          <div className='info-section'>
            <p>
              Lorient Agglomération se réserve le droit de modifier, de mettre à jour ou d&apos;interrompre temporairement ou non tout ou partie du Site et ce, sans information préalable.
            </p>
          </div>
        </div>
        <div id='copyright-info-5' className='info-container'>
          <p className='title-section'>
            5. LOI APPLICABLE - ATTRIBUTION DE JURIDICTION
          </p>
          <br />
          <div className='info-section'>
            <p>
            Les Conditions Générales sont régies par le droit français. En cas de litige, les tribunaux français seront seuls compétents.
            </p>
            <br />
            <p>Dans le cas où l’une des stipulations des Conditions Générales s’avèrerait nulle, illicite ou inopposable, la validité, la licéité ou l’application des autres stipulations des Conditions Générales n’en sera aucunement affectée ou altérée.</p>

          </div>
        </div>
      </div>
    </div>
  );
};