import React, { useEffect } from 'react';

export const PageAccessibility = () => {
  useEffect(() => {
    document.title = "HITinéraire - Déclaration d'accessibilité";
  }, []);
  return (
    <div id='page-accessibility' className='page-info'>
      <div className="layer-ring">
        <svg width="100%" viewBox="0 0 1119 1119" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="559.5" cy="559.5" r="494.5" stroke="#ED7F15" strokeWidth="90"/>
        </svg>
      </div>
      <div id='header-contact' className='header-info'>
        <h2>
        Déclaration d&apos;accessibilité
        </h2>
        <p>
            Établie le 24 mai 2024.
        </p>
        <p>
            Lorient Agglomération s’engage à rendre son service accessible, conformément à l’article 47 de la loi n° 2005-102 du 11 février 2005.
        </p>
        <p>
            Cette déclaration d’accessibilité s’applique à  <a href="https://hitineraires.lorient-agglo.bzh/">https://hitineraires.lorient-agglo.bzh/</a>
        </p>
      </div>
      <div id='accessibily-content' className='info-content'>
        <div id='status-info' className='info-container'>
          <p className='title-section'>
            État de conformité
          </p>
          <br />
          <div id='status-section' className='info-section'>
            <p>
              <b>Hitinéraires</b> est <b>non conforme</b> avec le RGAA. Le site n’a encore pas été audité. L’audit sera fait très prochainement.
            </p>
          </div>
        </div>
        <div id='content-info' className='info-container'>
          <p className='title-section'>
            Contenus non accessibles
          </p>
          <br />
          <div id='content-section' className='info-section'>
            <p>
            Le site n’a encore pas été audité, l’accessibilité étant de la nature même de la plateforme, nous nous sommes attachés à être au plus près d’une conformité totale avec le RGAA.            </p>
          </div>
        </div>
        <div id='improve-contact-info' className='info-container'>
          <p className='title-section'>
            Amélioration et contact
          </p>
          <br />
          <div id='improve-contact-section' className='info-section'>
            <p>
            Si vous n’arrivez pas à accéder à un contenu ou à un service, vous pouvez contacter le responsable de Hitinéraires pour être orienté vers une alternative accessible ou obtenir le contenu sous une autre forme.
            </p>
            <br />
            <ul id='list-contact' className='list-info'>
              <li>
                Téléphone : 02 90 74 73 20
              </li>
              <li>
                E-mail : <a href='mail:slenoxaic@agglo-lorient.fr'>slenoxaic@agglo-lorient.fr</a>
              </li>
              <li>
                Adresse : Maison de l’Agglomération, 56314 Lorient Cedex
              </li>
            </ul>
          </div>
        </div>
        <div id='recours-method-info' className='info-container'>
          <p className='title-section'>
          Voie de recours
          </p>
          <br />
          <div id='recours-method-section' className='info-section'>
            <p>
                Cette procédure est à utiliser dans le cas suivant : vous avez signalé au responsable du site internet un défaut d’accessibilité qui vous empêche d’accéder à un contenu ou à un des services du portail et vous n’avez pas obtenu de réponse satisfaisante.          
            </p>
            <p>
                Vous pouvez :
            </p>
            <br />
            <ul id='list-recours' className='list-info'>
              <li>
                Écrire un message au <a href="https://formulaire.defenseurdesdroits.fr/" target='_blank' rel="noreferrer"> Défenseur des droits </a>
              </li>
              <li>
                Contacter le délégué du <a href="https://www.defenseurdesdroits.fr/saisir/delegues" target='_blank' rel="noreferrer"> le délégué du Défenseur des droits dans votre région </a>
              </li>
              <li>
                Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) :<br/>
                Défenseur des droits<br />
                Libre réponse 71120 75342 Paris CEDEX 07
              </li>
            </ul>
          </div>
        </div>
        <p>Cette déclaration d’accessibilité a été créée le 24 mai 2024.</p>
      </div>
    </div>
  );
};