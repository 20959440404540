import React, { useEffect } from 'react';

export const PagePlanSite = () => {

  useEffect(() => {
    document.title = 'HITinéraire - Plan du site';
  }, []);

  return (
    <div id='page-plan' className='page-info'>
      <div className="layer-ring">
        <svg width="100%" viewBox="0 0 1119 1119" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="559.5" cy="559.5" r="494.5" stroke="#ED7F15" strokeWidth="90"/>
        </svg>
      </div>
      <div id='header-plan' className='header-info'>
        <h2>
            Plan du site
        </h2>
      </div>
      <div id='plan-site-header' className='info-content'>
        <ul id='list-plan-site'>
          <li className='item-list-plan'>
            <a href = '/' target='_blank'>
              Des parcours adaptés à vos capacités dans votre quotidien
            </a>
          </li>
          <li className='item-list-plan'>
            <a href = '../#decouvrir' target='_blank'>
              Une application pour la mobilité de tous qui s’adapte aux capacités
            </a>
          </li>
          <li className='sub-list'>
            <ul>
              <li className='item-sub-plan'>
                Parcours
              </li>
              <li className='item-sub-plan'>
                Obstacles
              </li>
              <li className='item-sub-plan'>
                Alertes
              </li>
              <li className='item-sub-plan'>
                Temps réel
              </li>
              <li className='item-sub-plan'>
                Point d’intérêt
              </li>
              <li className='item-sub-plan'>
                Multi-modals
              </li>
              <li className='item-sub-plan'>
                <a href='../#access_app' target='_blank'>
                  Découvrir l’application
                </a>
              </li>
            </ul>
          </li>
          <li className='item-list-plan'>
            <a href = '../#faq' target='_blank'>
              Retrouvez toutes les réponses à vos questions pour planifier votre parcours
            </a>
          </li>
          <li className='sub-list'>
            <ul>
              <li className='item-sub-plan'>
                Comment déterminer un itinéraire ?
              </li>
              <li className='item-sub-plan'>
                Les horaires des bus sont-ils en temps réel ?
              </li>
              <li className='item-sub-plan'>
                La feuille de route me sert à quoi ?
              </li>
              <li className='item-sub-plan'>
                Comment partager un itinéraire ?
              </li>
              <li className='item-sub-plan'>
                Comment signaler un obstacle ?
              </li>
              <li className='item-sub-plan'>
                Comment ajouter ou modifier un lieu sur la carte ?
              </li>
              <li className='item-sub-plan'>
                Puis-je utiliser mon parcours dans un autre outil ?
              </li>
              <li className='item-sub-plan'>
                Je ne veux pas prendre les transports collectifs, je fais comment ?
              </li>
              <li className='item-sub-plan'>
                Je veux donner un avis sur l’outil
              </li>
            </ul>
          </li>
          <li className='item-list-plan'>
            <a href = '../#a-propos' target='_blank'>
              À propos
            </a>
          </li>
          <li className='item-list-plan'>
            <a href = '/hitineraire' target='_blank'>
              HITinéraire
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};