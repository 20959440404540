import React from 'react';

export const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <menu className="no-style flex gap-2 justify-center">
          <li>
            <a href="/plan">
                        Plan du site
            </a>
          </li>
          <li>
            <a href="/mentions-legales">
                        Mentions légales
            </a>
          </li>
          <li>
            <a href="/contact">
                        Nous contacter
            </a>
          </li>
          <li>
            <a href="/donnees-personelles">
                        Données personelles
            </a>
          </li>
          <li>
            <a href="/déclaration-accessibilité">
                        Accessibilité: Non conforme
            </a>
          </li>
        </menu>
      </div>
    </footer>
  );
};