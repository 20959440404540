import React from 'react';

export const FaqDetailsAndSummary = ({summary, details}) => {
  return (
    <details>
      <summary>
        <h3>
          {summary}
        </h3>
      </summary>
      <p>
        {details}
      </p>
    </details>
  );
};